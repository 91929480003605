$ml4s-gutter: 1rem;

$ml4s-red: #ed4a4c;
$ml4s-clay: #f4f5f2;
$ml4s-silver: #b3b4b2;
$ml4s-gray: #323232;
$ml4s-white: #f4f5f2;
$ml4s-black: #323232;

$breakpoints: (
  small: 600px,
  medium: 900px,
  large: 1020px,
  xlarge: 1400px
);

@mixin font-sans-serif {
  font-family: "Source Sans Pro Regular";
  font-weight: normal;
  letter-spacing: 0.7px;
  line-height: 1;
}
@mixin font-serif() {
  font-family: "lemonde-journal", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.25px;
  line-height: 1.25;
}

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem auto;

  &-excerpt {
    font-style: italic;
    font-size: 1.125rem;
    letter-spacing: 0.54px;
    line-height: 1.666;
    padding-bottom: 3rem;
    padding-top: 2rem;
    max-width: 940px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &-title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 2.25px;
    padding: 1rem 0 0 0;
    max-width: 940px;
    padding-left: 1rem;
    padding-right: 1rem;
    hyphens: auto;
    word-break: break-word;

    @include respond-to("small") {
      line-height: 1.3;
    }
  }
  &-author {
    line-height: 1.4;
  }
  &-date {
    min-width: 120px;

    @include respond-to("small") {
      margin-right: 2rem;
    }
  }
  &-meta {
    @include font-sans-serif();

    color: $ml4s-red;
    display: flex;
    font-size: 1rem;
    flex-direction: row;
    padding-bottom: 2rem;
    text-transform: uppercase;
    justify-content: space-between;
    max-width: 940px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;

    @include respond-to("small") {
      justify-content: flex-start;
    }
  }
  &-image {
    overflow: hidden;
    margin-top: 5rem;
    position: relative;
    width: 100%;

    &:after {
      content: "";
      display: block;
      padding-bottom: 62.5%;
    }
  }
  &-imageElement {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  &-social {
    @include font-sans-serif();
    font-size: 1rem;
    text-transform: uppercase;
  }
  &-socialItems {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
  }
  &-socialItem {
    margin-right: 1rem;
  }
  &-socialItemLink {
    color: $ml4s-red;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }
  &-links {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 940px;
    width: 100%;
  }
  &-navigation {
    margin-bottom: 2rem;
  }
  &-navigationItem {
    color: $ml4s-red;
    margin-bottom: 1rem;
  }
  &-navigationItemLink {
    color: inherit;
    min-width: 280px;
  }

  @include respond-to("small") {
    &-links {
      flex-direction: row;
    }
    &-navigation {
      margin-bottom: 0;
      margin-right: 6rem;
    }
    &-title {
      font-size: 3.375rem;
      font-weight: 700;
      line-height: 1.1;
      letter-spacing: 2.75px;
    }
  }
}
