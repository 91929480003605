$ml4s-gutter: 1rem;

$ml4s-red: #ed4a4c;
$ml4s-clay: #f4f5f2;
$ml4s-silver: #b3b4b2;
$ml4s-gray: #323232;
$ml4s-white: #f4f5f2;
$ml4s-black: #323232;

$breakpoints: (
  small: 600px,
  medium: 900px,
  large: 1020px,
  xlarge: 1400px
);

@mixin font-sans-serif {
  font-family: "Source Sans Pro Regular";
  font-weight: normal;
  letter-spacing: 0.7px;
  line-height: 1;
}
@mixin font-serif() {
  font-family: "lemonde-journal", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.25px;
  line-height: 1.25;
}

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

.comments {
  margin-bottom: 2rem;
  margin-top: 2rem;

  &-headline {
    color: $ml4s-black;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: 1.07px;
    margin-bottom: 1rem;
  }
  &-responses {
    border-left: 2px solid $ml4s-silver;
    padding-left: 1rem;
    margin-top: 1rem;
  }
}
