$ml4s-gutter: 1rem;

$ml4s-red: #ed4a4c;
$ml4s-clay: #f4f5f2;
$ml4s-silver: #b3b4b2;
$ml4s-gray: #323232;
$ml4s-white: #f4f5f2;
$ml4s-black: #323232;

$breakpoints: (
  small: 600px,
  medium: 900px,
  large: 1020px,
  xlarge: 1400px
);

@mixin font-sans-serif {
  font-family: "Source Sans Pro Regular";
  font-weight: normal;
  letter-spacing: 0.7px;
  line-height: 1;
}
@mixin font-serif() {
  font-family: "lemonde-journal", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.25px;
  line-height: 1.25;
}

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

.commentForm {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0.5rem;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  padding: 1rem;

  textarea {
    resize: none;
  }
  &.is-hidden {
    display: none;
  }
  &-form {
    width: 100%;
  }
  &-headline {
    margin-bottom: 1rem;
  }
  &-field {
    margin-bottom: 1rem;
  }
  &-label {
    @include font-sans-serif();

    color: $ml4s-black;
    display: block;
    font-size: 0.75rem;
    letter-spacing: 0.7px;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
  &-input {
    display: block;
    border: 1px solid $ml4s-silver;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
  &-submit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &-replyButton,
  &-submitButton {
    @include font-sans-serif();

    appearance: none;
    border: none;
    border-radius: 0.5rem;
    background-color: $ml4s-red;
    color: #fff;
    cursor: pointer;
    font-size: 0.75rem;
    letter-spacing: 0.7px;
    margin-bottom: 0.5rem;
    text-transform: uppercase;

    padding: 0.5rem 1rem;
  }
  &-replyButton[disabled],
  &-submitButton[disabled] {
    background-color: silver;
  }

  &-privacyPolicy {
    margin: 0 0 1rem 0;

    a {
      color: $ml4s-red
    }
  }
  &-replyButton {
    margin-top: 1rem;

    &.is-hidden {
      display: none;
    }
  }
}
