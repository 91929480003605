$ml4s-gutter: 1rem;

$ml4s-red: #ed4a4c;
$ml4s-clay: #f4f5f2;
$ml4s-silver: #b3b4b2;
$ml4s-gray: #323232;
$ml4s-white: #f4f5f2;
$ml4s-black: #323232;

$breakpoints: (
  small: 600px,
  medium: 900px,
  large: 1020px,
  xlarge: 1400px
);

@mixin font-sans-serif {
  font-family: "Source Sans Pro Regular";
  font-weight: normal;
  letter-spacing: 0.7px;
  line-height: 1;
}
@mixin font-serif() {
  font-family: "lemonde-journal", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.25px;
  line-height: 1.25;
}

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

.stage {
  background-color: black;
  margin-bottom: 2rem;
  min-height: 80vh;
  max-height: 90vh;
  overflow: hidden;
  position: relative;

  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .swiper-slide {
    .card {
      position: absolute;
      bottom: 3rem;
      left: 1rem;
      right: 1rem;
      width: auto;
    }
  }

  &-content {
    position: relative;
    height: 100%;
    margin: 0 auto;
    // max-width: 1600px;
  }

  &-media {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  &-mediaElement {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    max-width: none;
    width: auto;
    transform: translateX(-50%);
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }

  @include respond-to("small") {
    min-height: 50vh;
    max-height: 70vh;
    &-mediaElement {
      height: auto;
      width: 100%;
    }
    .swiper-slide {
      .card {
        right: auto;
        max-width: 580px;
      }
    }
  }
}
