$ml4s-gutter: 1rem;

$ml4s-red: #ed4a4c;
$ml4s-clay: #f4f5f2;
$ml4s-silver: #b3b4b2;
$ml4s-gray: #323232;
$ml4s-white: #f4f5f2;
$ml4s-black: #323232;

$breakpoints: (
  small: 600px,
  medium: 900px,
  large: 1020px,
  xlarge: 1400px
);

@mixin font-sans-serif {
  font-family: "Source Sans Pro Regular";
  font-weight: normal;
  letter-spacing: 0.7px;
  line-height: 1;
}
@mixin font-serif() {
  font-family: "lemonde-journal", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.25px;
  line-height: 1.25;
}

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  line-height: 1.6666666667;
  letter-spacing: 0.54px;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  &--leftAligned {
    > .wysiwyg {
      align-items: flex-start !important;
    }
  }
  &--factbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: none !important;
    //justify-content: space-between;

    > .wysiwyg {
      align-items: flex-start !important;
      //max-width: 600px;
    }
  }
  &--wide {
    .content-marginalColumn {
      margin-bottom: 1.5rem;
    }
    .content-quote {
      color: $ml4s-red;
      font-size: 2.8rem;
      font-weight: 700;
      letter-spacing: 2.25px;
      line-height: 1.3333333333;
      margin-bottom: 1.5rem;
      word-break: break-word;
      hyphens: auto;
    }
    .content-image {
      margin-top: 2rem;

      .content-imageCaption {
        @include font-sans-serif();

        font-size: 0.75rem;
        margin-top: 0.5rem;
        line-height: 1.3;
      }
      .content-imageContainer {
        position: relative;
        overflow: hidden;

        &:after {
          content: "";
          display: block;
          padding-bottom: 62.5%;
        }
      }
      .content-imageElement {
        background-color: $ml4s-black;
        position: absolute;
        left: 0;
        top: 0;
        height: auto;
        width: 100%;
      }
    }
  }
  .wysiwyg {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  img {
    display: block;
    height: auto;
    width: 100%;
  }
  a {
    color: $ml4s-red;
  }
  h1,
  h2,
  h3 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 2.25px;
    margin-bottom: 1rem;
  }
  i,
  em {
    font-style: italic;
  }
  b,
  strong {
    color: $ml4s-red;
  }
  p {
    margin-bottom: 1.5rem;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  blockquote {
    color: $ml4s-red;
    font-size: 2rem;
    font-weight: 700;
    hyphens: auto;
    letter-spacing: 2.25px;
    line-height: 1.25;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.5rem;
    width: 100%;
    
    p {
      margin-bottom: 0;
      max-width: none;
    }
  }

  .content-factbox {
    align-items: flex-start;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding: 1rem;
    width: 100%;

    ol,
    ul {
      list-style: square;
      list-style-position: inside;
      text-indent: -22px;
      padding-left: 22px;

      li {
        margin-bottom: 1rem;
      }
    }

    .wysiwyg {
      @include font-sans-serif();

      font-size: 1rem;
      letter-spacing: 1.12;
      line-height: 1.5;
    }
  }

  .content-factboxHeadline {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    letter-spacing: 1.25;
    line-height: 1.4;
  }

  @include respond-to("medium") {
    max-width: 940px;

    blockquote {
      line-height: 1.3333333333;
    }

    &--wide {
      flex-direction: row;
      justify-content: space-between;

      p {
        max-width: 530px !important;
      }
    }
    .content-factbox {
      //max-width: 420px;
    }
    &-marginalColumn {
      margin-bottom: 0;
      max-width: 370px;
    }

    p {
      max-width: 740px;
    }
    h1,
    h2,
    h3,
    blockquote {
      // width: 740px;
    }
  }
}
