$ml4s-gutter: 1rem;

$ml4s-red: #ed4a4c;
$ml4s-clay: #f4f5f2;
$ml4s-silver: #b3b4b2;
$ml4s-gray: #323232;
$ml4s-white: #f4f5f2;
$ml4s-black: #323232;

$breakpoints: (
  small: 600px,
  medium: 900px,
  large: 1020px,
  xlarge: 1400px
);

@mixin font-sans-serif {
  font-family: "Source Sans Pro Regular";
  font-weight: normal;
  letter-spacing: 0.7px;
  line-height: 1;
}
@mixin font-serif() {
  font-family: "lemonde-journal", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.25px;
  line-height: 1.25;
}

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

@import "./../node_modules/reset-css/sass/reset";

@font-face {
  font-family: "Source Sans Pro Regular";
  src: url("./../assets/fonts/source-sans-pro-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro Semi Bold";
  src: url("./../assets/fonts/source-sans-pro-semi-bold.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

html {
  @include font-serif();
  font-size: 14px;

  &::-webkit-scrollbar {
    display: none;
  }
  @include respond-to("small") {
    font-size: 14px;
  }
  @include respond-to("medium") {
    font-size: 16px;
  }
}

body {
  background-color: $ml4s-clay;
  color: $ml4s-black;
  -ms-overflow-style: none;
}
.main {
  padding-top: 55px;

  @include respond-to("medium") {
    padding-top: 90px;
  }
}

.cta {
  @include font-sans-serif();

  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  transition: transform ease-in-out 300ms;
  position: relative;

  span {
    &:after {
      content: "";
      display: inline-block;
      width: 5rem;
    }
    &:before {
      content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57.35 7.36'><polygon points='53.39 0 52.88 0.55 55.85 3.31 0 3.31 0 4.05 55.85 4.05 52.88 6.81 53.39 7.36 57.35 3.68 53.39 0' style='fill: %23ED4A4C'/></svg>");
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 4rem;
    }
  }

  &:hover {
    color: $ml4s-black;
    transform: translateX(0.75rem);

    span {
      &:before {
        content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57.35 7.36'><polygon points='53.39 0 52.88 0.55 55.85 3.31 0 3.31 0 4.05 55.85 4.05 52.88 6.81 53.39 7.36 57.35 3.68 53.39 0' style='fill: %23323232'/></svg>");
      }
    }
  }
}

.sprite {
  height: 0px;
  overflow: hidden;
}

@import "./section.scss";
