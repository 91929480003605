.section {
  &-container {
    margin: 0px auto;
    padding-left: 1rem;
    padding-right: 1rem;
    //max-width: 1120px;
    max-width: 1600px;
  }
  &--sidebar {
    .section-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 1600px;
    }
    article {
      width: 100%;
    }
    aside {
      display: block;

      &.section-sidebar--home {
        display: none;
      }
    }
  }

  @include respond-to("xlarge") {
    &--sidebar {
      .section-container {
        flex-direction: row;
      }
      article {
        width: 70%;
      }
      aside {
        //border: 1px solid red;
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: flex-start;
        align-items: flex-end;
        max-height: calc(100vh - 8rem);
        overflow-y: auto;
        width: 30%;
        position: sticky;
        top: 8rem;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        &.section-sidebar--home {
          display: flex;
        }

        .has-sticky-header & {
          max-height: calc(100vh - 4rem);
          top: 4rem;
        }
      }
    }
  }
}
