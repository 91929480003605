$ml4s-gutter: 1rem;

$ml4s-red: #ed4a4c;
$ml4s-clay: #f4f5f2;
$ml4s-silver: #b3b4b2;
$ml4s-gray: #323232;
$ml4s-white: #f4f5f2;
$ml4s-black: #323232;

$breakpoints: (
  small: 600px,
  medium: 900px,
  large: 1020px,
  xlarge: 1400px
);

@mixin font-sans-serif {
  font-family: "Source Sans Pro Regular";
  font-weight: normal;
  letter-spacing: 0.7px;
  line-height: 1;
}
@mixin font-serif() {
  font-family: "lemonde-journal", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.25px;
  line-height: 1.25;
}

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

.cardList {
  &-item {
    border-top: 1px solid $ml4s-silver;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 3rem;
    width: 100%;

    &:hover {
      .cardList-mediaElement {
        transform: scale(1.1);
      }
    }
  }
  &-items {
    display: flex;
    flex-direction: column;
  }
  &-headline {
    @include font-sans-serif();

    font-size: 0.875rem;
    margin-top: 5rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
  &-card {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  &-media {
    background-color: $ml4s-black;
    margin-top: 2rem;
    position: relative;
    overflow: hidden;
    width: 100%;

    &:after {
      content: "";
      display: block;
      padding-bottom: 61%;
    }
  }

  &-mediaElement {
    transition: transform 500ms ease-in-out;
    transform-origin: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
  }

  @include respond-to("small") {
    &-item {
      flex-direction: row;
    }
    &-card {
      width: 30%;
    }
    &-media {
      margin-top: 0;
      width: calc(70% - 2rem);
    }
  }
}
