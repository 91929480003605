$ml4s-gutter: 1rem;

$ml4s-red: #ed4a4c;
$ml4s-clay: #f4f5f2;
$ml4s-silver: #b3b4b2;
$ml4s-gray: #323232;
$ml4s-white: #f4f5f2;
$ml4s-black: #323232;

$breakpoints: (
  small: 600px,
  medium: 900px,
  large: 1020px,
  xlarge: 1400px
);

@mixin font-sans-serif {
  font-family: "Source Sans Pro Regular";
  font-weight: normal;
  letter-spacing: 0.7px;
  line-height: 1;
}
@mixin font-serif() {
  font-family: "lemonde-journal", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.25px;
  line-height: 1.25;
}

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

.mobileMenu {
  display: none;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0;
  transition: opacity 400ms linear;
  z-index: 1000;

  &.is-open {
    display: flex;
    opacity: 1;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    margin: 0px auto;
    width: 100%;
  }

  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
  }

  &-logo {
    position: relative;
    height: auto;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: 8rem;

    a {
      display: block;
      height: 100%;
      background-image: url(./../assets/images/machine-learning-for-science.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 24.84375%;
    }
  }

  &-powered {
    background-image: url(./../assets/images/cluster-of-excellence.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    display: block;
    width: 120px;

    &:after {
      content: "";
      display: block;
      padding-bottom: 40%;
    }
  }

  &-poweredBy {
    @include font-sans-serif();

    font-size: 0.75rem;
    border-bottom: 1px solid $ml4s-silver;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  &-university {
    background-image: url(./../assets/images/university-logo.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    display: block;
    width: 6rem;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;

    &:after {
      content: "";
      display: block;
      padding-bottom: 25.67811935%;
    }
  }

  &-closeButton {
    background-image: url("data:image/svg+xml,%3Csvg id='Ebene_1' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 29.99 30'%3E%3Cpath d='M16.33,15l8.55-8.55a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0l-8.55,8.56L6.36,5A1,1,0,0,0,5,5,1,1,0,0,0,5,6.45L13.5,15,5,23.55A1,1,0,0,0,6.36,25l8.56-8.56L23.47,25a1,1,0,0,0,1.41-1.42Z' style='fill: %23323232'/%3E%3C/svg%3E%0A");
    background-size: 100% 100%;
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    width: 2rem;
    height: 2rem;
  }
  &-navigation {
    border-top: 1px solid $ml4s-black;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
  }
  &-languages {
    color: $ml4s-black;
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }
  &-languageLink {
    @include font-sans-serif();

    color: $ml4s-black;
    text-decoration: none;
    text-transform: uppercase;
  }
  &-menuItem {
    border-bottom: 1px solid $ml4s-black;
    padding-bottom: 1rem;
    padding-top: 1rem;
    text-align: left;
    width: 100%;
  }
  &-menuLink {
    color: $ml4s-black;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: $ml4s-red;
    }
  }

  @include respond-to("medium") {
    display: none;
  }
}
