$ml4s-gutter: 1rem;

$ml4s-red: #ed4a4c;
$ml4s-clay: #f4f5f2;
$ml4s-silver: #b3b4b2;
$ml4s-gray: #323232;
$ml4s-white: #f4f5f2;
$ml4s-black: #323232;

$breakpoints: (
  small: 600px,
  medium: 900px,
  large: 1020px,
  xlarge: 1400px
);

@mixin font-sans-serif {
  font-family: "Source Sans Pro Regular";
  font-weight: normal;
  letter-spacing: 0.7px;
  line-height: 1;
}
@mixin font-serif() {
  font-family: "lemonde-journal", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.25px;
  line-height: 1.25;
}

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

.header {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  transition: 300ms all ease-in-out;

  &.headroom--pinned {
    transform: translateY(0px);
  }
  &.headroom--unpinned {
    transform: translateY(-64px);
  }

  &-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    max-width: 1600px;
    margin: 0px auto;

    @include respond-to("medium") {
      justify-content: center;
    }
  }

  &-university {
    background-image: url(./../../assets/images/university-logo.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    display: block;
    width: 6rem;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;

    &:after {
      content: "";
      display: block;
      padding-bottom: 25.67811935%;
    }
  }
  &-powered {
    background-image: url(./../../assets/images/cluster-of-excellence.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    display: none;

    width: 8rem;
    position: absolute;
    right: 1rem;
    top: 52%;
    transform: translateY(-50%);
    font-size: 0;

    &:after {
      content: "";
      display: block;
      padding-bottom: 40%;
    }

    @include respond-to("medium") {
      display: block;
    }
  }
  &-languages {
    color: $ml4s-black;
    display: flex;
    font-size: 0.75rem;
    line-height: 1.75rem;
    position: fixed;
    top: 0.5rem;
    right: 1rem;
  }
  &-languageItem {
    &:after {
      content: "|";
      display: inline-block;
      margin: 0 0.25rem;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
    &.is-active {
      text-decoration: underline;
    }
  }
  &-logo {
    position: relative;
    height: auto;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: 8rem;

    a {
      display: block;
      height: 100%;
      background-image: url(./../../assets/images/machine-learning-for-science.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 24.84375%;
    }
  }
  &-languageLink {
    color: inherit;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
  }
  &-navigation {
    @include font-sans-serif();

    background-color: $ml4s-gray;
    font-size: 0.875rem;
    line-height: 1.75rem;
    color: $ml4s-white;
    display: none;
    height: 1.75rem;
    width: 100%;

    @include respond-to("medium") {
      display: block;
    }
  }
  &-navigationWrapper {
    max-width: 1600px;
    margin: 0px auto;
    position: relative;
  }
  &-menu {
    display: flex;
    justify-content: center;
  }
  &-menuItem {
    margin-left: 1rem;
    margin-right: 1rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
    &.is-active {
      text-decoration: underline;
    }
  }
  &-menuLink {
    color: inherit;
    text-decoration: inherit;
    text-transform: uppercase;
  }
  &-mobileMenuToggle {
    background-image: url("data:image/svg+xml,%3Csvg id='Ebene_1' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 29.99 30'%3E%3Cpath d='M28,8.19H1.82a1,1,0,0,1,0-2H28a1,1,0,1,1,0,2Z' style='fill: %23323232'/%3E%3Cpath d='M28,16H1.82a1,1,0,0,1,0-2H28a1,1,0,1,1,0,2Z' style='fill: %23323232'/%3E%3Cpath d='M28,23.81H1.82a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z' style='fill: %23323232'/%3E%3C/svg%3E%0A");
    background-size: 100% 100%;
    height: 2rem;
    display: block;
    width: 2rem;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
  @include respond-to("small") {
    &-university {
      width: 8rem;
    }
  }
  @include respond-to("medium") {
    &-languages {
      color: $ml4s-white;
      display: flex;
      position: absolute;
      top: auto;
      bottom: 0;
      right: 1rem;
    }
    &-university {
      display: block;
    }
    &-mobileMenuToggle {
      display: none;
    }
  }
}
